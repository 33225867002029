import React from "react";
import { FaTools } from "react-icons/fa"; // Icons for skills, tools, education

const experience = [
  {
    title: "Junior Web Developer",
    company: "Ar-IBT",
    date: "2023 January - 2023 June",
    description:
      "Building modern, responsive web apps using React, Next.js, and Tailwind CSS. Focused on performance and accessibility.",
  },
  {
    title: "Front End Developer",
    company: "Hub It",
    date: "2022 August - 2022 December",
    description:
      "Developed e-commerce websites using HTML, CSS, and JavaScript. Worked closely with UX/UI designers to optimize site performance.",
  },
];

const education = [
  {
    degree: "Bachelor of Information and Technology",
    institution: "Kent College, Sydney",
    year: "2024- Present",
    description:
      "Currently Studying",
  },
];

const skills = [
  { name: "React", level: "80%" },
  { name: "Node.js", level: "25%" },
  { name: "Tailwind CSS", level: "90%" },
  { name: "JavaScript", level: "85%" },
];

const tools = ["Git", "Figma", "VS Code",];

const ResumeSection = () => {
  return (
    <section id="resume-section" className="py-24 bg-main text-white px-5 lg:px-20">
      {/* Header */}
      <div className="text-center mb-16">
        <h2 data-aos="fade-right" className="text-4xl sm:text-5xl font-bold text-secondary">
          My Resume
        </h2>
      </div>

      {/* Experience Section */}
      <div className="mb-20">
        <h3 className="text-3xl font-semibold text-secondary mb-6">Experience</h3>
        {experience.map((exp, index) => (
          <div
            key={index}
            className="bg-black bg-opacity-60 p-6 rounded-xl shadow-xl mb-6 transition-all duration-300 hover:bg-opacity-80"
            data-aos="fade-up"
            data-aos-delay={index * 200}
          >
            <h4 className="text-2xl font-semibold text-white">{exp.title}</h4>
            <p className="text-lg text-gray-400">{exp.company}</p>
            <p className="text-sm text-gray-500">{exp.date}</p>
            <p className="mt-2 text-gray-300">{exp.description}</p>
          </div>
        ))}
      </div>

      {/* Education Section */}
      <div className="mb-20">
        <h3 className="text-3xl font-semibold text-secondary mb-6">Education</h3>
        {education.map((edu, index) => (
          <div
            key={index}
            className="bg-black bg-opacity-60 p-6 rounded-xl shadow-xl mb-6 transition-all duration-300 hover:bg-opacity-80"
            data-aos="fade-up"
            data-aos-delay={index * 200}
          >
            <h4 className="text-2xl font-semibold text-white">{edu.degree}</h4>
            <p className="text-lg text-gray-400">{edu.institution}</p>
            <p className="text-sm text-gray-500">{edu.year}</p>
            <p className="mt-2 text-gray-300">{edu.description}</p>
          </div>
        ))}
      </div>

      {/* Skills Section */}
      <div className="mb-20">
        <h3 className="text-3xl font-semibold text-secondary mb-6">Skills</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {skills.map((skill, index) => (
            <div
              key={index}
              className="bg-black bg-opacity-60 p-6 rounded-xl shadow-xl mb-6 transition-all duration-300 hover:bg-opacity-80"
              data-aos="fade-up"
              data-aos-delay={index * 200}
            >
              <h4 className="text-2xl font-semibold text-white">{skill.name}</h4>
              <div className="w-full bg-gray-700 h-2 mt-2 rounded-full">
                <div
                  className="bg-secondary h-2 rounded-full"
                  style={{ width: skill.level }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Tools Section */}
      <div>
        <h3 className="text-3xl font-semibold text-secondary mb-6">Tools</h3>
        <div className="flex flex-wrap gap-8 justify-center">
          {tools.map((tool, index) => (
            <div
              key={index}
              className="bg-black bg-opacity-60 p-6 rounded-xl shadow-xl mb-6 transition-all duration-300 hover:bg-opacity-80"
              data-aos="fade-up"
              data-aos-delay={index * 200}
            >
              <div className="flex items-center justify-center space-x-2 text-xl text-white">
                <FaTools />
                <span>{tool}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ResumeSection;
