import React from "react";
import Slider from "react-slick"; // Import the carousel component
import { FaHeart, FaPuzzlePiece, FaUserAlt } from "react-icons/fa"; // Icons for passion, trivia, philosophy

const aboutContent = [
  {
    title: "My Passion",
    icon: <FaHeart className="text-red-500 text-4xl" />,
    description:
      "I am passionate about creating seamless and dynamic web experiences that bring value to users. I believe in continuous learning and staying updated with new technologies.",
  },
  {
    title: "My Philosophy",
    icon: <FaUserAlt className="text-blue-500 text-4xl" />,
    description:
      "I approach every project with a user-first mindset, ensuring my designs and code provide real value. I believe in simplicity, usability, and efficiency in everything I create.",
  },
  {
    title: "Fun Trivia",
    icon: <FaPuzzlePiece className="text-green-500 text-4xl" />,
    description:
      "When I'm not coding, I enjoy solving puzzles and playing chess. I also have a soft spot for photography and love capturing moments during my travels.",
  },
];

const AboutSection = () => {
  const settings = {
    dots: true, // Show dots for navigation
    infinite: true,
    speed: 500, // Slide speed
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1,
    autoplay: true, // Autoplay slides
    autoplaySpeed: 3000, // Autoplay interval
    arrows: false, // Hide arrows (if you want to only use dots)
  };

  return (
    <section id="about-section" className="py-24 bg-main text-white px-5 lg:px-20">
      {/* Header */}
      <div className="text-center mb-16">
        <h2 data-aos="fade-right" className="text-4xl sm:text-5xl font-bold text-secondary">
          About Me
        </h2>
      </div>

      {/* Carousel / Slider Section */}
      <div className="max-w-3xl mx-auto">
        <Slider {...settings}>
          {aboutContent.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center bg-black bg-opacity-60 p-6 rounded-xl shadow-xl mb-6 transition-all duration-300 hover:bg-opacity-80"
              data-aos="fade-up"
              data-aos-delay={index * 200}
            >
              <div className="mb-4">{item.icon}</div>
              <h3 className="text-3xl font-semibold text-white mb-4">{item.title}</h3>
              <p className="text-lg text-gray-300 text-center">{item.description}</p>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default AboutSection;
