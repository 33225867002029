import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  const navLinks = [
    { label: 'Home', href: '#home-section' },
    { label: 'Services', href: '#service-section' },
    { label: 'Projects', href: '#project-section' },
    { label: 'Resume', href: '#resume-section' },
    { label: 'About', href: '#about-section' },
    { label: 'Contact', href: '#contact-section' },
  ];

  const socialLinks = [
    { icon: <FaFacebookF />, href: 'https://www.facebook.com/aryandev.pandey.7', label: 'Facebook' },
    { icon: <FaInstagram />, href: 'https://www.instagram.com/nameisaryan18/', label: 'Instagram' },
    { icon: <FaLinkedin />, href: 'https://www.linkedin.com/in/aryan-dev-pandey-03ab62255/', label: 'LinkedIn' },
  ];

  return (
    <footer className="bg-main text-white py-8">
      <div className="container mx-auto px-8">
        {/* Navigation Links */}
        <div className="flex flex-wrap justify-center mb-6">
          {navLinks.map((link, index) => (
            <a
              key={index}
              href={link.href}
              className="mx-4 text-lg hover:text-secondary transition duration-300"
            >
              {link.label}
            </a>
          ))}
        </div>

        {/* Social Media Links */}
        <div className="flex justify-center gap-6 mb-6">
          {socialLinks.map((social, index) => (
            <a
              key={index}
              href={social.href}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={social.label}
              className="p-3 bg-secondary rounded-full text-main hover:bg-orange transition duration-300"
            >
              {social.icon}
            </a>
          ))}
        </div>

        {/* Copyright Section */}
        <div className="text-center text-gray-400 text-sm">
          © {new Date().getFullYear()} Aryan Dev. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
