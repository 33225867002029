import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeroSection from './Components/HeroSection';
import TopNav from './LayOut/TopNav';
import ServicesSection from './Components/ServiceSection';
import ProjectSection from './Components/ProjectSection';
import ResumeSection from './Components/ResumeSection';
import AboutSection from './Components/AboutSection';
import ContactSection from './Components/ContactSection';
import SideNav from './LayOut/SideNav';
// import RMSSection from './Components/RMSSection';
import Footer from './LayOut/Footer';




const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  }, []);

  return (
    <div className='font-poppins'>
      <TopNav/>
      <SideNav/>
     <HeroSection/>
     <ServicesSection/>
     <ProjectSection/>
      {/* <RMSSection/> */}
     <ResumeSection/>
     <AboutSection/>
     <ContactSection/>
     <Footer/>
    </div>
  );
};

export default App;
