import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';

const TopNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 500, // Animation duration in milliseconds
      easing: 'ease-in-out', // Animation easing
    });
  }, []);

  // Navigation items
  const navItems = [
    { name: 'Service', to: 'service-section' },
    { name: 'Project', to: 'project-section' },
    { name: 'Resume', to: 'resume-section' },
    { name: 'About', to: 'about-section' },
  ];

  return (
    <nav id="top-nav" className="bg-main text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex items-center">
            <ScrollLink
              to="home-section"
              smooth={true}
              duration={500}
              className="text-xl font-bold cursor-pointer"
            >
              AryanDev
            </ScrollLink>
          </div>
          {/* Navigation Items */}
          <div className="hidden md:flex space-x-8">
            {navItems.map((item, index) => (
              <ScrollLink
                key={index}
                to={item.to}
                smooth={true}
                duration={500}
                offset={-70}
                className="hover:text-secondary transition-colors cursor-pointer"
              >
                {item.name}
              </ScrollLink>
            ))}
          </div>
          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="text-white focus:outline-none focus:ring-2 focus:ring-secondary"
              aria-label="Toggle menu"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
        {/* Mobile Navigation Items with AOS */}
        {isOpen && (
          <div
            data-aos="fade-down"
            className="md:hidden bg-secondary text-white rounded-md shadow-md mt-2"
          >
            <div className="space-y-2 py-2 px-4">
              {navItems.map((item, index) => (
                <ScrollLink
                  key={index}
                  to={item.to}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  className="block text-sm hover:text-main transition-colors cursor-pointer"
                  onClick={() => setIsOpen(false)} // Close menu on click
                >
                  {item.name}
                </ScrollLink>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default TopNav;
