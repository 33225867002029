import React from 'react';

const HeroSection = () => {
  return (
    <section
  id="home-section"
  className="h-screen flex flex-col justify-center items-center text-center bg-main text-white"
>
  {/* Greeting Section */}
  <h1
    data-aos="fade-up"
    className="text-4xl sm:text-5xl font-bold mb-4 flex flex-col gap-8"
  >
    <p>Hi!</p>
    <p className="text-secondary">I am Aryan Dev</p>
  </h1>

  {/* Description Section */}
  <p
    data-aos="fade-up"
    data-aos-delay="200"
    className="text-lg sm:text-xl max-w-2xl"
  >
    Building modern, responsive, and efficient web solutions that bring your ideas to life.
  </p>

  {/* Contact Button */}
  <a
    href="#contact-section"
    data-aos="fade-up"
    data-aos-delay="400"
    className="mt-6 bg-secondary text-main px-6 py-3 rounded-md shadow-md hover:bg-orange transition"
  >
    Contact Me
  </a>
</section>

  );
};

export default HeroSection;
