import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';
import {MdClose} from "react-icons/md"

const ContactSection = () => {
  const [notification, setNotification] = useState(null); // For displaying notifications
  
  const closeNotification = () => {
    setNotification(null);
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    fullName: Yup.string().required('Full Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    message: Yup.string().required('Message is required'),
  });

  // Form fields data array
  const formFields = [
    {
      name: 'fullName',
      type: 'text',
      placeholder: 'Full Name',
    },
    {
      name: 'email',
      type: 'email',
      placeholder: 'Email',
    },
    {
      name: 'message',
      type: 'textarea',
      placeholder: 'Your Message',
      rows: 6,
    },
  ];

  // Handle form submission with EmailJS
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setNotification({ status: 'sending', message: 'Sending message...' });

    // EmailJS send function
    emailjs
      .sendForm(
        'service_knp9pct', // Replace with your service ID
        'template_g1sxbci', // Replace with your template ID
        '#contact-form', // The form id
        'YY3ajSig_-J6-G5PH' // Replace with your user ID
      )
      .then(
        (response) => {
          setNotification({ status: 'sent', message: 'Message sent successfully!' });
          resetForm();
        },
        (error) => {
          setNotification({ status: 'error', message: 'Failed to send message.' });
        }
      )
      .finally(() => setSubmitting(false));
  };

  return (
    <section id="contact-section" className="py-24 bg-main text-white px-5 lg:px-20 relative">
      {/* Notification Pop-up */}
      {notification && (
        <div
          className={`absolute top-6 right-6 p-4 rounded-md shadow-lg text-white ${
            notification.status === 'sending'
              ? 'bg-blue-500'
              : notification.status === 'sent'
              ? 'bg-green-500'
              : 'bg-red-500'
          }`}
        >
          {/* Close Button */}
          <button
            onClick={closeNotification}
            className="absolute top-2 right-2 text-white text-xl"
            aria-label="Close"
          >
            <MdClose />
          </button>
          {notification.message}
        </div>
      )}

      {/* Header */}
      <div className="text-center mb-12">
        <h2 className="text-4xl sm:text-5xl font-bold text-secondary mb-8">Contact Me</h2>
        <p className="text-lg text-gray-300">Feel free to reach out to me for any inquiries.</p>
      </div>

      {/* Contact Form */}
      <div className="max-w-3xl mx-auto">
        <Formik
          initialValues={{
            fullName: '',
            email: '',
            message: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form id="contact-form" className="space-y-6">
              {formFields.map((field, index) => (
                <div key={index}>
                  <Field
                    as={field.type === 'textarea' ? 'textarea' : 'input'}
                    type={field.type}
                    name={field.name}
                    rows={field.rows || undefined}
                    className={`w-full p-4 rounded-md bg-black bg-opacity-50 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-secondary ${
                      errors[field.name] && touched[field.name] ? 'border-2 border-red-500' : ''
                    }`}
                    placeholder={field.placeholder}
                  />
                  {errors[field.name] && touched[field.name] && (
                    <div className="text-red-500 text-sm mt-2">{errors[field.name]}</div>
                  )}
                </div>
              ))}

              {/* Submit Button */}
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full py-3 mt-4 bg-secondary text-main font-semibold rounded-md shadow-md hover:bg-orange transition"
              >
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default ContactSection;
