import React from 'react';
import WebDevelopment from "../Resources/WebDevelopment.avif"
import Figma from "../Resources/UIUX.avif"
import Database from "../Resources/Database.jpg"

const services = [
  {
    title: 'Web Development',
    description:
      'Building modern, responsive websites using React, Next.js, and Tailwind CSS to create seamless digital experiences.',
    image: WebDevelopment, // Add your service images
  },
  {
    title: 'UI/UX Design',
    description:
      'Designing elegant and user-friendly interfaces, creating wireframes, prototypes, and mockups using tools like Figma and Adobe XD.',
    image: Figma, // Add your service images
  },
  {
    title: 'Backend Solutions',
    description:
      'Developing scalable and secure backend solutions with Node.js, Express, and MongoDB to ensure seamless integration and performance.',
    image: Database, // Add your service images
  },
];

const ServicesSection = () => {
  return (
    <section id="service-section" className="py-24 bg-main text-white px-5 lg:px-20">
      <div className="max-w-7xl mx-auto text-center mb-12">
        <h2 data-aos="fade-right" className="text-4xl sm:text-5xl font-bold text-secondary mb-8">
          My Services
        </h2>
      </div>

      {services.map((service, index) => (
        <div
          key={index}
          className={`flex flex-col  lg:flex-row ${
            index % 2 === 0 ? 'lg:flex-row-reverse ' : 'justify-between'
          } items-center justify-between mb-20`}
          data-aos="fade-up"
          data-aos-delay={index * 200}
        >
          {/* Image Section */}
          <div className="lg:w-1/2 w-full  mb-8 lg:mb-0 ">
            <img
              src={service.image}
              alt={service.title}
              className="w-full h-full lg:w-3/4 lg:h-3/4 object-cover rounded-xl shadow-xl "
            />
          </div>

          {/* Text Section */}
          <div className="lg:w-1/2 w-full text-center lg:text-left px-4">
            <h3 className="text-3xl font-semibold mb-4">{service.title}</h3>
            <p className="text-lg text-gray-300">{service.description}</p>
          </div>
        </div>
      ))}
    </section>
  );
};

export default ServicesSection;
