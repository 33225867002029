import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { MdArrowForward, MdClose } from 'react-icons/md';

const SideNav = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Set initial state based on screen size

  // Detect screen size changes
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  // Detect scroll position to show sidebar on larger screens
  const handleScroll = () => {
    const topNav = document.getElementById('top-nav');
    if (topNav) {
      const scrollPosition = window.scrollY;
      setShowSidebar(scrollPosition > topNav.offsetHeight);
    }
  };

  useEffect(() => {
    // Add listeners for resize and scroll
    window.addEventListener('resize', handleResize);
    if (!isMobile) window.addEventListener('scroll', handleScroll);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  const navItems = [
    { to: 'home-section', label: 'Home' },
    { to: 'service-section', label: 'Services' },
    { to: 'project-section', label: 'Projects' },
    { to: 'resume-section', label: 'Resume' },
    { to: 'about-section', label: 'About' },
    { to: 'contact-section', label: 'Contact' },
  ];

  return (
    <>
      {/* Sidebar for larger screens */}
      {!isMobile && (
        <div
          className={`fixed left-0 top-1/4 transform -translate-y-1/4 p-3 z-20 bg-main text-white rounded-lg shadow-lg transition-opacity duration-300 ${
            showSidebar ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <nav>
            <ul>
              {navItems.map((item, index) => (
                <li key={index} className="mb-3">
                  <ScrollLink
                    to={item.to}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    className="cursor-pointer text-sm hover:text-secondary"
                  >
                    {item.label}
                  </ScrollLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}

      {/* Button for mobile screens */}
      {isMobile && !showSidebar && (
        <div
          className="fixed left-2 top-1/2 transform -translate-y-1/2 z-30 p-2 bg-main text-white rounded-lg shadow-lg cursor-pointer"
          onClick={() => setShowSidebar(true)}
        >
          <MdArrowForward size={24} />
        </div>
      )}

      {/* Sidebar for mobile screens */}
      {isMobile && showSidebar && (
        <div
          className="fixed left-0 top-72 bottom-0 z-40 bg-main text-white p-4 shadow-lg transition-transform duration-300"
          style={{
            width: '150px',
            height: "300px",
            transform: showSidebar ? 'translateX(0)' : 'translateX(-100%)',
          }}
        >
          <nav>
            <ul>
              {navItems.map((item, index) => (
                <li key={index} className="mb-4">
                  <ScrollLink
                    to={item.to}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    className="cursor-pointer text-sm hover:text-secondary"
                    onClick={() => setShowSidebar(false)} // Close after clicking
                  >
                    {item.label}
                  </ScrollLink>
                </li>
              ))}
            </ul>
          </nav>
          {/* Close button */}
          <button
            className="absolute top-4 right-4 text-white"
            onClick={() => setShowSidebar(false)}
          >
            <MdClose size={24} />
          </button>
        </div>
      )}
    </>
  );
};

export default SideNav;
