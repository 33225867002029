import React from 'react';
import ProjectImage1 from "../Resources/work1.png"; // Add your project images
import ProjectImage2 from "../Resources/rms4.png"; // Add your project images
import ProjectImage3 from "../Resources/ttt1.png"; // Add your project images

const projects = [
  {
    title: 'Project One',
    description: 'A modern e-commerce website built with React and Tailwind CSS.',
    image: ProjectImage1,
    link: 'https://hubit.com.np/', // Link to project details or live demo
  },
  {
    title: 'Project Two',
    description: 'Design UX/UI and Code for Front End For Restaurent Management System',
    image: ProjectImage2,
    link: '/rms-section',
  },
  {
    title: 'Project Three',
    description: 'Build Tic Tac Toe game while learning React Js.',
    image: ProjectImage3,
    link: 'https://tictactoe-game-8ghzs3189-arbhram.vercel.app/',
  },
];

const ProjectSection = () => {
  return (
    <section id="project-section" className="py-24 bg-main text-white px-5 lg:px-20">
      <div className="max-w-7xl mx-auto text-center mb-12">
        <h2 data-aos="fade-right" className="text-4xl sm:text-5xl font-bold text-secondary mb-8">
          My Projects
        </h2>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
        {projects.map((project, index) => (
          <div
            key={index}
            className="relative group"
            data-aos="fade-up"
            data-aos-delay={index * 200}
          >
            {/* Image Section with Blurred Shadow */}
            <div className="relative overflow-hidden rounded-xl shadow-2xl">
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-full object-cover transform transition duration-500 group-hover:scale-105"
              />
              {/* Blurred shadow effect */}
              <div className="absolute inset-0 bg-black opacity-50 filter blur-lg"></div>
            </div>

            {/* Project Title and Description */}
            <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-4">
              <h3 className="text-2xl font-semibold text-white mb-2">{project.title}</h3>
              <p className="text-lg text-gray-300">{project.description}</p>
              <a
                href={project.link}
                className="mt-4 text-black bg-secondary px-6 py-2 rounded-md hover:bg-orange transition"
              >
                View Project
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProjectSection;
